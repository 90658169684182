import React from "react"
import Layout from "../components/layout"
import { useStoryblokState } from "gatsby-source-storyblok"
import Sidebar from "../components/Sidebar_Navigation"
import SideBarPage from "../components/DocPage"
export default function PageIndex({ pageContext, location }) {
  const story = useStoryblokState(pageContext.story, location)
  const { content } = story || {}
  const { crumbLabel, breadcrumbs } = content || {}

  return (
    <Layout
      className="layoutclass"
      location={location}
      full_slug={story?.full_slug}
      lang={story?.lang}
      crumb={crumbLabel}
      breadcrumbs={breadcrumbs}
      meta={{ ...story?.content?.Meta, tracking_variable: story?.content?.tracking_variable }}
      pathname={pageContext.pathname}
    >
      <main className="container">
        <div className="sidebar-section">
          <div className="sidebar-section-left">
            <Sidebar story={story} location={location} lang={story?.lang} />
          </div>
          <div className={`main-content ${story?.slug == `confidentiality-policy` ? 'confidentiality-policy' : ''}`}>
            <SideBarPage story={story} location={location} lang={story?.lang}/>
          </div>
        </div>
      </main>
    </Layout>
  );
}